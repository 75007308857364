<template>
  <b-form-group
    label="Adres"
    label-for="address"
  >
    <b-form-textarea
      id="address"
      v-model="dataItem.address"
      placeholder="Adres"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  name: 'Address',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    dataItem() {
      return this.$store.getters['suppliers/dataItem']
    },
  },
}
</script>
