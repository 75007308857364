<template>
  <b-form-group
    label="Ünvan"
    :label-for="'related_person_types_' + itemKey"
  >
    <validation-provider
      #default="{ errors }"
      name="Ünvan"
      rules="required"
    >
      <b-form-input
        :id="'related_person_types_' + itemKey"
        v-model="dataItem.related_persons[itemKey].related_person_types"
        placeholder="Giriniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'RelatedPersonTypes',
  components: {
    BFormInput,
    BFormGroup,
    ValidationProvider,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['suppliers/dataItem']
    },
  },
  created() {
    localize('tr')
  },

}
</script>
