<template>
  <b-form-group
    label="Faaliyet Gösterilen Sektörler"
    label-for="customer_sectors"
  >
    <!--<validation-provider
      #default="{ errors }"
      name="Faaliyet Gösterilen Sektörler"
      rules="required"
    >-->
    <v-select
      id="customer_sectors"
      v-model="dataItem.sectors"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
      multiple
    />
    <!--<small class="text-danger">{{ errors[0] }}</small>-->
    <!-- </validation-provider>-->
  </b-form-group>
</template>

<script>
import { /* ValidationProvider */ localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Sectors',
  components: {
    BFormGroup,
    vSelect,
    // ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['suppliers/dataItem']
    },
    dataList() {
      return this.$store.getters['sectors/dataList']
    },
  },
  created() {
    this.getDataList()
    localize('tr')
  },
  methods: {
    getDataList() {
      this.$store.dispatch('sectors/getDataList', {
        select: [
          'sectors.id AS id',
          'sectors.title AS title',
        ],
        where: {
          'sectors.suppliers >=': 1,
        },
      })
    },
  },
}
</script>
