<template>
  <b-form-group
    label="Telefon"
    label-for="phone"
  >
    <validation-provider
      #default="{ errors }"
      name="Telefon"
      rules="required"
    >
      <b-input-group>
        <b-input-group-prepend is-text>
          TR (+9)
        </b-input-group-prepend>
        <cleave
          id="phone"
          v-model="dataItem.phone"
          class="form-control"
          :raw="true"
          :options="options.phone"
          placeholder="Telefon"
          @input="phoneControl"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'
import { required } from '@validations'

export default {
  name: 'Name',
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    Cleave,
    ValidationProvider,
  },
  data() {
    return {
      required,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['suppliers/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    phoneControl() {
      if (this.dataItem.phone.length === 10) {
        this.$store.dispatch('suppliers/getPhoneControl', {
          phone: this.dataItem.phone,
          id: this.dataItem.id,
        })
      } else {
        this.$store.commit('suppliers/SET_PHONE_CONTROL', {
          status: false,
          id: null,
        })
      }
    },
  },
}
</script>
