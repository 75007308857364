<template>
  <b-card title="Fatura Bilgileri">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <tax-office />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <tax-number />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <country />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <city />
      </b-col>
      <b-col cols="12">
        <address-area />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import TaxOffice from '@/views/Admin/Suppliers/elements/TaxOffice.vue'
import TaxNumber from '@/views/Admin/Suppliers/elements/TaxNumber.vue'
import Country from '@/views/Admin/Suppliers/elements/Country.vue'
import City from '@/views/Admin/Suppliers/elements/City.vue'
import AddressArea from '@/views/Admin/Suppliers/elements/Address.vue'

export default {
  name: 'BillingInformation',
  components: {
    BRow,
    BCol,
    BCard,
    TaxOffice,
    TaxNumber,
    Country,
    City,
    AddressArea,
  },
}
</script>
