<template>
  <div>
    <public />
    <activities />
    <related-persons />
    <billing-information />
  </div>
</template>

<script>
import Public from '@/views/Admin/Suppliers/SupplierForm/Public.vue'
import RelatedPersons from '@/views/Admin/Suppliers/SupplierForm/RelatedPersons.vue'
import BillingInformation from '@/views/Admin/Suppliers/SupplierForm/BillingInformation.vue'
import Activities from '@/views/Admin/Suppliers/SupplierForm/Activities.vue'

export default {
  name: 'SupplierForm',
  components: {
    Activities,
    Public,
    RelatedPersons,
    BillingInformation,
  },
}
</script>
